import { AnyAction } from "redux"

export type Orientation = 'Portrait' | 'Landscape';

const initialState = {
    config: {
        orientation: 'Portrait',
        columns: 1,
        rows: 1
    }
}

export default function appReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case 'config': {
            let config = {
                ...state.config,
                ...action.payload
            };

            return {
                ...state,
                config
            };
        }
        default:
            return state
    }
}